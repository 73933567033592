import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'ciudades', pathMatch: 'full' },

  {
    path: 'inicio', 
  loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)},
  {
    path: 'mapa/:id',
    loadChildren: () => import('./pages/mapa/mapa.module').then( m => m.MapaPageModule)
  },
  {
    path: 'ciudades',
    loadChildren: () => import('./pages/ciudades/ciudades.module').then( m => m.CiudadesPageModule)
  },
  {
    path: 'iglesias/:id',
    loadChildren: () => import('./pages/iglesias/iglesias.module').then( m => m.IglesiasPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./pages/contacto/contacto.module').then( m => m.ContactoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
