// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyDfEwHJSzfdElf2xv2GQphLefSBU5V4LAo",
    authDomain: "procesion-e3417.firebaseapp.com",
    databaseURL: "https://procesion-e3417.firebaseio.com",
    projectId: "procesion-e3417",
    storageBucket: "procesion-e3417.appspot.com",
    messagingSenderId: "350001198858",
    appId: "1:350001198858:web:3063a51dac67042b356cf3",
    measurementId: "G-5E0PSS9SVY"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
